:root {
  --application-background-color: #ffffff;
  --application-border-color: #e6e9ef;
  --secondary-text-color: #676879;
  --primary-text-color: ;
}

#root{
  height: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background: var(--grey-background-color);
  height: 100vh;
}
.overview-section {
  flex-direction: column;
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--layout-border-color);
  margin: 16px;
  min-height: 90%;
}

.overview-section-pulse-card-wrapper {
  padding: 16px 32px;
}

.flex{
  display: flex;
  height: 100%;
}

.loader{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

